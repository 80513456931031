import React from "react";
import Fade from "react-reveal/Fade";

import "./MainPage.css";
import MainPageForm from "../MainPageForm/MainPageForm";
import Intro from "../Intro/Intro";
import MPText from "./MPText/MPText";
import MPList from "./MPList/MPList";
import BottomPage from "../BottomWebsite/BottomWebsite";

import moversPic from "./mover.png";
import pic1 from "../Intro/img/movingFam.jpg";

const MainPage = (props) => {
  return (
    <div>
      <Intro
        pic={pic1}
        introText={"Moving is Simple!"}
        text={
          "We guarantee reliable & prompt service performed by trained and courteous movers."
        }
      />

      <div className="mpBackground">
        <div className="mainPageContainer">
          <Fade left>
            <div className="mainPageForm">
              <MainPageForm />
            </div>
          </Fade>
          <Fade right>
            <div className="mpTextWr">
              <h3>A tradition in excellence!</h3>
              <p>
                Get started today with a free quote and find out why ABC New
                Movers is the #1 rated Vancouver moving company.
              </p>
              <div className="mainImg">
                <img src={moversPic} alt="" />
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <MPText />
      <MPList />
      <BottomPage />
    </div>
  );
};

export default MainPage;
