import React from "react";
import LocationControl from "../LocationControl";
import vPic from "../../Locations/img/vancouver.jpg";

const Vancouver = () => {
  return (
    <div>
      <LocationControl
        introText1={"Vancouver's #1 Movers"}
        text1={"A tradition in excellence!"}
        pic2={vPic}
        locationH3Text={"We know Vancouver"}
        locationPText={
          "We know how stressful moving can be, especially in Vancouver where everything is just a little more stressful. Let us move you and remove the stress."
        }
        address={[
          "ABC New Movers ",
          <br></br>,
          "1212 Howe St Vancouver, BC, V6Z 2M9, Canada",
          <br></br>,
          "604-727-2210",
          <br></br>,
          "778-819-1015",
          <br></br>,
          "abcnewmovers@gmail.com",
        ]}
        lat1={49.27738}
        lng1={-123.12733}
        // text={"Heyyyyyyyy"}
      />
    </div>
  );
};

export default Vancouver;
