import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import "./Links.css";

import instIcon from "./img/insta.png";
import fbIcon from "./img/facebook.png";
import twIcon from "./img/twitter.png";

const Links = () => {
  return (
    <div className="linksContainer">
      <Fade left>
        <ul className="ulLinks">
          <li>
            <a href="https://www.instagram.com/abcnewmovers/">
              <img src={instIcon} alt="" />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/people/Jonathan-New-Move/100009042879225">
              <img src={fbIcon} alt="" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/abcnewmove">
              <img src={twIcon} alt="" />
            </a>
          </li>
        </ul>
      </Fade>
      <Fade right>
        <ul className="ulLinks">
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/locations">Locations</Link>
          </li>

          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/careers">Careers</Link>
          </li>
          <li>
            <Link to="/moving-supplies">Moving Supplies</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
        </ul>
      </Fade>
    </div>
  );
};

export default Links;
