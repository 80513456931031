import React from "react";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import Intro from "../Intro/Intro";
import BottomPage from "../BottomWebsite/BottomWebsite";
import TPics from "../TPics/TPics";

import comP from "./img/photo1.jpg";
import f1 from "./img/f1.png";
import f2 from "./img/f2.jpg";

const Urgent = (props) => {
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABC New Movers - Moving is Simple!</title>
        <meta
          name="description"
          content="ABC New Move offers reliable residential, commercial moving, delivery and storage services in Metro Vancouver, British Columbia"
        />
        <meta
          name="keywords"
          content="abc new move, abcnewmoving,  moving, mover, company, storage, depot, movers, urgent, transportation, track, last minute moving, depository,
      reliable, service, home, apartment, condo, delivery, pick up, phone, free quote, BC, British Columbia, residential,  commercial salvatory, lower mainland, Vancouver,
      North Vancouver, West Vancouver, Moving Canada BC, moving, move, mover, storage, residential,  commercial , depot, movers, urgent moving,  last minute moving, transportation, 
      track, last minute moving,  depository, reliable, service, North Vancouver, Coquitlam,  moving track, moving companies movers, packers and movers, removal companies, moving services, moving companies near me, movers near me,furniture movers,
long distance movers, national moving companies, moving containers, moving vans, moving help, removals, long distance moving companies, moving quotes, local movers, the moving company, house movers, house removals,
removal van, cross country movers, packers & movers, local moving companies, moving van, relocation services, best moving companies, nationwide movers, professional movers, cross country moving companies, furniture removals,
moving costs, moving options, office movers, removal vans, professional packers and movers, nationwide moving companies, moving storage containers, full service movers, interstate moving companies, the movers, affordable movers,
relocation companies, home moving companies, moving men, find moving service, interstate movers, professional moving companies, moving estimate, top rated moving companies, state to state moving companies, full service moving companies,
moving and storage companies, state to state movers, american moving company, apartment movers, moving companies prices, local removal companies, professional packers, moving van lines, commercial movers,
find a mover, moving storage, best long distance moving companies, van lines, removal services, national removal companies, movers n packers, moving labor, packers and movers services, best movers, home moving services,
out of state movers, hire movers, best rated moving companies, moving company quotes, moving truck companies, house moving companies, moving van companies, american movers, packing services, household moving companies, national movers,
budget moving, office relocation, moving cost estimator, of state moving companies, cheapest moving company, house removal companies, best long distance movers, packing companies, furniture removal companies, house moving costs,
affordable moving companies, furniture moving companies, packers and movers near me, household movers, removal firms, moving companies in, van lines moving company,"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Intro
        pic={comP}
        introText={"Moving Supplies"}
        text={`We sell and rent a variety of different moving supplies. `}
      />
      <div className="CFBC">
        <div className="CFB"></div>
      </div>
      <div className="CSBCC">
        <div className="CSBC">
          <div className="CSBCT">
            <Fade left>
              <h3>Moving Supplies in GVA</h3>
              <p>
                ABC New Movers is your one-stop shop for moving supplies and
                support – moving boxes and blankets, bubble wrap, packing
                peanuts, tape, and more. When you need expert packing and moving
                advice on supplies and packaging materials or custom packing for
                valuable or fragile items, let ABC New Movers help you.
              </p>
            </Fade>
          </div>
          <div className="CSBCI">
            <Fade right>
              <img
                src={f1}
                alt=""
                height="250"
                width="400"
                style={{ marginTop: "20px" }}
              />
            </Fade>
          </div>
        </div>
      </div>
      <div className="CTBCC">
        <div className="CTBC">
          <div className="CTBCT">
            <Fade right>
              <br></br>
              <p>
                Believe it or not, the boxes we have traditionally used to move,
                store and transport our goods and belongings have evolved over
                the past century. While corrugated boxes and pre-cut cardboard
                boxes have been around since the mid-to-late 1800s, they have
                been improved upon to be more sturdy, durable, and even
                waterproof. Moving boxes now come in different shapes and sizes
                and are made of recyclable materials to help save waste from
                landfills.
              </p>
            </Fade>
          </div>
          <div className="CTBCI">
            <Fade left>
              <img src={f2} alt="" height="350" width="100" />
            </Fade>
          </div>
        </div>
      </div>
      <TPics />
      <BottomPage />
    </div>
  );
};

export default Urgent;
