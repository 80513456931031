import React from "react";
import Fade from "react-reveal/Fade";
import Intro from "../Intro/Intro";
import BottomPage from "../BottomWebsite/BottomWebsite";
import TPics from "../TPics/TPics";

import comP from "./img/packing.jpg";
import f1 from "./img/f1.png";
import f2 from "./img/f2.jpg";

const Packing = (props) => {
  return (
    <div style={{ width: "100%" }}>
      <Intro
        pic={comP}
        introText={
          "We are the GVA packing experts the area trusts for all packing needs"
        }
      />
      <div className="CFBC">
        <div className="CFB">
          <Fade top>
            <h2>Experience Full Service Packing and Moving in GVA</h2>
          </Fade>
          <Fade bottom>
            <p>
              ABC New Movers packing services give you the peace of mind in
              knowing your belongings are being packed and stored with care.
            </p>
          </Fade>
        </div>
      </div>
      <div className="CSBCC">
        <div className="CSBC">
          <div className="CSBCT">
            <Fade left>
              <h3>Packing Services</h3>
              <p>
                As Vancouver's best moving company, we understand that you have
                a busy schedule and may not have the time to complete the
                tedious task of packing and boxing all of your possessions. Our
                professional packing service provides a stress-free and
                efficient service option for our clients. Our Vancouver movers
                will show up on time and bring all of the supplies and equipment
                necessary to complete the work as a standard part of our
                commitment to great customer service.
              </p>
            </Fade>
          </div>
          <div className="CSBCI">
            <Fade right>
              <img src={f1} alt="" height="200" />
            </Fade>
          </div>
        </div>
      </div>
      <div className="CTBCC">
        <div className="CTBC">
          <div className="CTBCT">
            <Fade right>
              <p>
                At ABC New Movers, we understand that you may not have the time
                to pack for your move. Work and family obligations make it
                difficult to dedicate the necessary time to properly prepare for
                and pack items. With packing assistance from our team, we take
                the hassle out of the packing process. Our team will securely
                wrap, box, and/or crate your items to prevent damage during your
                move.
              </p>
            </Fade>
          </div>
          <div className="CTBCI">
            <Fade left>
              <img src={f2} alt="" />
            </Fade>
          </div>
        </div>
      </div>
      <TPics />
      <BottomPage />
    </div>
  );
};

export default Packing;
