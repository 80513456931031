import React from "react";
import Fade from "react-reveal/Fade";
import BottomPage from "../BottomWebsite/BottomWebsite";
import TPics from "../TPics/TPics";
import ImageGallery from "react-image-gallery";

import "./Gallery.css";

import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import img5 from "./img/5.png";
import img6 from "./img/6.png";

const images = [
  {
    original: img1,
  },
  {
    original: img2,
  },
  {
    original: img3,
  },
  {
    original: img4,
  },
  {
    original: img5,
  },
  {
    original: img6,
  },
];

const Gallery = () => {
  return (
    <div>
      <div className="headerGallery">
        <div className="textGalleryController">
          <h2>Gallery</h2>
        </div>
      </div>
      <div className="galleryCn">
        <div className="gallery">
          <ImageGallery items={images} />
        </div>
      </div>

      <TPics />
      <BottomPage />
    </div>
  );
};

export default Gallery;
