import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import "./Footer.css";
import MoverPic from "./mover.png";

const Footer = (props) => {
  return (
    <div className="Fcontroler">
      <Fade left bottom>
        <div className="Fbox1">
          <ul>
            <li>
              <Link to="/local">Residential Moving Services</Link>
            </li>
            <li>
              <Link to="/packing">Packing & Labour Moving Services</Link>
            </li>
            <li>
              <Link to="/commercial">Commercial Moving Services</Link>
            </li>
            <li>
              <Link to="/"></Link>
            </li>
            <li>
              <Link to="/storage">Storage Moving Services</Link>
            </li>
            <li>
              <Link to="/delivery">Delivery Services</Link>
            </li>
            <li>
              <Link to="/urgent">Urgent Moving</Link>
            </li>
            <li>
              <Link to="/moving-supplies">Moving Supplies</Link>
            </li>
          </ul>
        </div>
      </Fade>
      <div className="Fbox2">
        <ul>
          <Fade top>
            <li>
              <a href="https://www.websitepolicies.com/policies/view/Jr25q8Vq">
                Terms and conditions
              </a>
            </li>
            <li>
              <a href="https://www.websitepolicies.com/policies/view/5JeVaTNB">
                Privacy policy
              </a>
            </li>
          </Fade>
        </ul>
        <Fade bottom>
          <div className="fBox2Img">
            <img src={MoverPic} alt="" />
          </div>
        </Fade>
      </div>
      <Fade right bottom>
        <div className="Fbox3">
          <ul>
            <li>
              <p>ABC New Movers</p>
            </li>
            <li>
              <p>
                Facility: Unit 103, 15 Braid Street, New Westminster, V3L 5N7,
                BC
              </p>
            </li>
            <li>
              <p>abcnewmovers@gmail.com</p>
            </li>
            <li>
              <p>
                <a
                  href="/"
                  style={{
                    color: "white",
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  www.abcnewmovers.com
                </a>
              </p>
            </li>
            <li id="Fp">
              <p>© {new Date().getFullYear()} ABC New Movers</p>
              <p>All Rights Reserved</p>
            </li>
          </ul>
        </div>
      </Fade>
    </div>
  );
};

export default Footer;
