import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Intro from "../Intro/Intro";
import BottomPage from "../BottomWebsite/BottomWebsite";
import "./Locations.css";

import gvrd from "./img/GVRD.jpg";

const Locations = () => {
  return (
    <div>
      <Intro
        introText={"A Tradition in excellence!"}
        text={"We love to move our customers, no matter what city they’re in."}
        pic={gvrd}
      />
      <div className="locationsWrap">
        <div className="locationsControl">
          <ul className="lUlc">
            <Fade left>
              <li className="l1Box">
                <Link to="/locations/vancouver" className="MPLcontainers">
                  {" "}
                  Vancouver
                </Link>
              </li>
            </Fade>
            <Fade right>
              <li className="l2Box">
                {" "}
                <Link to="/locations/burnaby" className="MPLcontainers">
                  Burnaby
                </Link>
              </li>
            </Fade>
            <Fade left>
              <li className="l3Box">
                <Link to="/locations/coquitlam" className="MPLcontainers">
                  Coquitlam
                </Link>
              </li>
            </Fade>
            <Fade right>
              <li className="l4Box">
                <Link to="/locations/newwestminster" className="MPLcontainers">
                  New Westminster
                </Link>
              </li>
            </Fade>
          </ul>
        </div>
      </div>
      <BottomPage />
    </div>
  );
};

export default Locations;
