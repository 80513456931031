import React from "react";
import Fade from "react-reveal/Fade";
import Intro from "../Intro/Intro";
import BottomPage from "../BottomWebsite/BottomWebsite";
import TPics from "../TPics/TPics";

import comP from "./img/urgent.jpg";
import f1 from "./img/f1.webp";
import f2 from "./img/f2.jpg";

const Urgent = props => {
  return (
    <div style={{ width: "100%" }}>
      <Intro
        pic={comP}
        introText={"Urgent Moving"}
        text={`If you need to move urgently, or require a delivery/pickup service
            don’t hesitate to call us`}
      />
      <div className="CFBC">
        <div className="CFB"></div>
      </div>
      <div className="CSBCC">
        <div className="CSBC">
          <div className="CSBCT">
            <Fade left>
              <h3>Urgent Moving Service in VGA </h3>
              <p>
                Last Minute Moving Service If you need to move urgently, or
                require a delivery/pickup service don’t hesitate to call us.
                Here at ABC New Movers, we offer last minute moving services in
                Vancouver, North Vancouver, West Vancouver, Burnaby, New
                Westminster, Coquitlam, Port Coquitlam, Port Moody, Delta,
                Surrey, Langley, Pitt Meadows, Maple Ridge, Tsawwassen, Ladner
                and White Rock.
              </p>
            </Fade>
          </div>
          <div className="CSBCI">
            <Fade right>
              <img
                src={f1}
                alt=""
                height="300"
                width="150"
                style={{ marginTop: "20px" }}
              />
            </Fade>
          </div>
        </div>
      </div>
      <div className="CTBCC">
        <div className="CTBC">
          <div className="CTBCT">
            <Fade right>
              <h3>So call us if: </h3>
              <p>
                - Your pre-arranged movers didn’t show up <br></br>- Your
                pre-arranged movers came with a moving truck that’s too small
                <br></br>- Your movers came with a small moving truck<br></br>
                -You’re out time and need to move quickly<br></br>
                We are a team of professional and reliable movers who are ready
                to help you at anytime, no matter how big or small your move is!
                Our company has the solution: Call now! Our last minute ABC New
                Movers moving services are available 24 hours a day and 7 days a
                week.
              </p>
            </Fade>
          </div>
          <div className="CTBCI">
            <Fade left>
              <img src={f2} alt="" height="350" width="100" />
            </Fade>
          </div>
        </div>
      </div>
      <TPics />
      <BottomPage />
    </div>
  );
};

export default Urgent;
