import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Fade from "react-reveal/Fade";
import BottomPage from "../BottomWebsite/BottomWebsite";
import "./Contact.css";

const initialState = {
  fName: "",
  lName: "",
  email: "",
  topic: "",
  message: "",
  popUp: false,
};

class Contact extends Component {
  constructor(props) {
    super();
    this.state = initialState;
  }

  onFNameChange = (event) => {
    this.setState({ fName: event.target.value });
  };

  onLNameChange = (event) => {
    this.setState({ lName: event.target.value });
  };

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  onTopicChange = (event) => {
    this.setState({ topic: event.target.value });
  };

  onMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  onSubmitContactForm = (event) => {
    event.preventDefault();
    fetch("https://abcnewmove.herokuapp.com/sendContactEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fName: this.state.fName,
        lName: this.state.lName,
        email: this.state.email,
        topic: this.state.topic,
        message: this.state.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  sendConfirmEmail = (event) => {
    fetch("https://abcnewmove.herokuapp.com/confirmEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.email,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  onClick = (event) => {
    this.setState({ popUp: true });
    this.onSubmitContactForm(event);
    this.sendConfirmEmail(event);
  };

  render() {
    return (
      <div className="commentContainer">
        <div className="commentIntro">
          <div className="commentIntroText">
            <Fade left>
              <h2>ABC New Movers</h2>
            </Fade>
            <Fade right>
              <p>A tradition in excellence!</p>
            </Fade>
          </div>
        </div>
        <div className="formWrapControl">
          <div className="formTextControl">
            <Fade top>
              <h3>We want to hear from you</h3>
            </Fade>
            <Fade bottom>
              <p>
                Please get in touch with us for any of your moving needs. We can
                provide you with any info you need including a free moving
                consultation. Thanks for your interest!
              </p>
            </Fade>
          </div>
          <div className="formController1">
            <div className="contactAddress">
              <Fade left>
                <h4>ABC New Movers</h4>
                <p>
                  Facility: Unit 103, 15 Braid Street, New Westminster, V3L 5N7,
                  BC
                </p>
                <p>abcnewmovers@gmail.com</p>
              </Fade>
            </div>
            <Fade right>
              <div className="formController2">
                <h3>Contact Information</h3>
                <form onSubmit={this.onClick}>
                  <label htmlFor="fName">First name:</label>
                  <br />
                  <input
                    type="text"
                    id="fName"
                    name="fName"
                    placeholder="First Name"
                    onChange={this.onFNameChange}
                    value={this.state.fName}
                    required
                  />
                  <br />
                  <label htmlFor="lName">Last name:</label>
                  <br />
                  <input
                    type="text"
                    id="lName"
                    name="lName"
                    placeholder="Last name"
                    onChange={this.onLNameChange}
                    value={this.state.lName}
                    required
                  />
                  <br />
                  <label htmlFor="email">Email:</label>
                  <br />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={this.onEmailChange}
                    value={this.state.email}
                    required
                  />
                  <br />
                  <label htmlFor="topic">Topic:</label>
                  <br />
                  <select
                    name="topic"
                    id="topic"
                    required
                    placeholder="Select"
                    onChange={this.onTopicChange}
                    value={this.state.topic}
                  >
                    <option value="">select</option>
                    <option value="Pickup/Delivery">Pickup/Delivery</option>
                    <option value="Missing/Damage">Missing/Damage</option>
                    <option value="Billing">Billing</option>
                    <option value="Compliment">Compliment</option>
                    <option value="Other">Other</option>
                  </select>
                  <br />
                  <br />
                  <label htmlFor="message">Message:</label>
                  <br />
                  <input
                    style={{ height: "100px" }}
                    type="text"
                    id="message"
                    name="message"
                    onChange={this.onMessageChange}
                    value={this.state.message}
                    required
                  />
                  <br />
                  <div className="cBf">
                    <button id="secondAA" type="submit">
                      {" "}
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </Fade>
            <SweetAlert
              show={this.state.popUp}
              success
              title="Mail has been sent!"
              onConfirm={() => this.setState(initialState)}
            />
          </div>
        </div>
        <BottomPage />
      </div>
    );
  }
}

export default Contact;
