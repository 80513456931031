import React from "react";
import Fade from "react-reveal/Fade";
import "./MPText.css";

const MPText = () => {
  return (
    <div className="MPTextContainer">
      <div className="TextControl">
        <Fade top>
          <h4>ABC New Movers. Your Favorite Movers.</h4>
          <p>
            If you're in need of a locally owned and operated moving company in
            the Greater Vancouver Area, then look no further than ABC new
            Movers.
          </p>
        </Fade>
        <Fade bottom>
          <h4>We Have The Moving Solution You're Looking For.</h4>

          <p>
            Moving can be one of life's most stressful events. Our efficient and
            reliable moving crews can take care of all of your moving day needs.
            From packing up your possessions to transporting them with care,
            we've got the skills, resources, and equipment needed to tackle the
            job.
          </p>
        </Fade>
        {/* <h4>The Movers You Can Trust.</h4> 
        <p>
          When you’re moving the things that mean the most to you, ABC New
          Movers is the moving company you can trust. We have the experience and
          knowledge to ensure efficient, trouble-free moving. We are providing
          packing, moving and on-site storage services for residential,
          commercial and corporate moves throughout Vancouver Area.
        </p>
        <p>
          Whether you are moving out of Vancouver and need long distance movers,
          or are within the Vancouver area and need a local moving company, we
          are experts with a proven track record of friendly, professional and
          timely moving services. 
        </p> */}
      </div>
    </div>
  );
};

export default MPText;
