import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import "./MPList.css";
//TODO real pic
const MPList = () => {
  return (
    <div className="MPLcontainer">
      <div style={{ width: "80%" }}>
        <ul className="MPLul">
          <Fade left>
            <li id="MPLfLi">
              <Link to="/commercial" className="MPLcontainers">
                <h5>
                  Commercial <br></br> Moving
                </h5>
              </Link>
            </li>
            <li id="MPLsLi">
              <Link to="/local" className="MPLcontainers">
                <h5>
                  Residential <br></br> Moving
                </h5>
              </Link>
            </li>
          </Fade>
          <Fade right>
            <li id="MPLtLi">
              <Link to="/packing" className="MPLcontainers">
                <h5>
                  Packaging & <br></br> Labour <br></br> Services
                </h5>
              </Link>
            </li>
            <li id="MPLfrLi">
              <Link to="/storage" className="MPLcontainers">
                <h5>
                  Storage <br></br> Services
                </h5>
              </Link>
            </li>
          </Fade>
          <Fade left>
            <li id="MPLfthLi">
              <Link to="/delivery" className="MPLcontainers">
                <h5>
                  Delivery <br></br> Services
                </h5>
              </Link>
            </li>
          </Fade>
          <Fade right>
            <li id="MPshLi">
              <Link to="/urgent" className="MPLcontainers">
                <h5>
                  Urgent <br></br> Moving
                </h5>
              </Link>
            </li>
          </Fade>
        </ul>
      </div>
    </div>
  );
};

export default MPList;
