import React from "react";
import LocationControl from "../LocationControl";
import vPic from "../../Locations/img/burnaby.jpg";

const Burnaby = () => {
  return (
    <LocationControl
      introText1={"Burnaby's #1 Movers"}
      text1={"A tradition in excellence!"}
      pic2={vPic}
      locationH3Text={"We know Burnaby"}
      locationPText={
        "We know how stressful moving can be, especially in Burnaby where everything is just a little more stressful. Let us move you and remove the stress."
      }
      address={[
        "ABC New Movers ",
        <br></br>,
        "7227 Royal Oak Ave, Burnaby, BC, V5J 0E2, Canada",
        <br></br>,
        "604-727-2210",
        <br></br>,
        "778-819-1015",
        <br></br>,
        "abcnewmovers@gmail.com",
      ]}
      lat1={49.2183}
      lng1={-122.989}
      // text={"Heyyyyyyyy"}
    />
  );
};

export default Burnaby;
