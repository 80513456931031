import React from "react";
import TextLoop from "react-text-loop";
import Fade from "react-reveal/Fade";
import "./Reviews.css";

const Reviews = (props) => {
  return (
    <div className="Rwrap">
      <div className="Rtext">
        <Fade top>
          <p>
            <TextLoop
              springConfig={{ stiffness: 70, damping: 31 }}
              adjustingSpeed={500}
              interval={4000}
              mask={true}
              noWrap={false}
              children={[
                `"ABC New Movers showed up right when they said they would, and worked hard, never slowing up. Thanks for everything!"`,
                `"Their staff communicated and responded consistently right up until moving day." `,
                `"Thank you ABC New Movers for our move to Vancouver. They were a great group of guys that did a perfect job of packing and unpacking."`,
                `"Our double move was extremely complicated, but it was almost stress free due to ABC New Mover's outstanding service."`,
                `"It's hard to find a reliable source to manage and handle your most prized possessions. I'm very grateful to ABC New Movers!"`,
                `"Good Service, nice owner, very considerate, fully advised"`,
                `"Perfect job and ABC New Movers guys where humble enough to always respond to my questions not minding how difficult the move was. I really recommend their services."`,
                `"Customer service and their transport facilities are best."`,
                `"The best moving company operated by young group of highly influential people."`,
                `"Such a nice work, friendly people, strong team work."`,
                `"They went above and beyond to ensure all the items were packed and also wrapped them in plastic/cardboard to ensure everything remains in good condition. If you are moving call them now!"`,
              ]}
            />
          </p>
        </Fade>
        <Fade bottom>
          <span id="span">
            <TextLoop
              springConfig={{ stiffness: 70, damping: 21 }}
              adjustingSpeed={0}
              interval={4000}
              noWrap={false}
              children={[
                "Alexander R.",
                "Miriam R.",
                "Lisa B.",
                "Cecil H.",
                "Bruce B.",
                "Victor K.",
                "Cheryl P.",
                "Lucky W.",
                "Paul W",
                "Simon K",
                "Trent M",
              ]}
            />
          </span>
        </Fade>
      </div>
    </div>
  );
};

export default Reviews;
