import React from "react";
import LocationControl from "../LocationControl";
import vPic from "../../Locations/img/coquitlam.jpg";

const Coquitlam = () => {
  return (
    <LocationControl
      introText1={"Coquitlam's #1 Movers"}
      text1={"A tradition in excellence!"}
      pic2={vPic}
      locationH3Text={"We know Coquitlam"}
      locationPText={
        "We know how stressful moving can be, especially in Coquitlam where everything is just a little more stressful. Let us move you and remove the stress."
      }
      address={[
        "ABC New Movers ",
        <br></br>,
        "620 Lea Ave, Coquitlam, BC V3J 4H2, Canada",
        <br></br>,
        "604-727-2210",
        <br></br>,
        "778-819-1015",
        <br></br>,
        "abcnewmovers@gmail.com",
      ]}
      lat1={49.26425}
      lng1={-122.8851}
      // text={"Heyyyyyyyy"}
    />
  );
};

export default Coquitlam;
