import React from "react";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import Intro from "../Intro/Intro";
import BottomPage from "../BottomWebsite/BottomWebsite";
import TPics from "../TPics/TPics";

import comP from "./img/local.jpg";
import f1 from "./img/f1.webp";
import f2 from "./img/f2.jpg";

const LocalMov = (props) => {
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABC New Movers - Moving is Simple!</title>
        <meta
          name="description"
          content="ABC New Move offers reliable residential, commercial moving, delivery and storage services in Metro Vancouver, British Columbia"
        />
        <meta
          name="keywords"
          content="abc new move, abcnewmoving,  moving, mover, company, storage, depot, movers, urgent, transportation, track, last minute moving, depository,
      reliable, service, home, apartment, condo, delivery, pick up, phone, free quote, BC, British Columbia, residential,  commercial salvatory, lower mainland, Vancouver,
      North Vancouver, West Vancouver, Moving Canada BC, moving, move, mover, storage, residential,  commercial , depot, movers, urgent moving,  last minute moving, transportation, 
      track, last minute moving,  depository, reliable, service, North Vancouver, Coquitlam,  moving track, moving companies movers, packers and movers, removal companies, moving services, moving companies near me, movers near me,furniture movers,
long distance movers, national moving companies, moving containers, moving vans, moving help, removals, long distance moving companies, moving quotes, local movers, the moving company, house movers, house removals,
removal van, cross country movers, packers & movers, local moving companies, moving van, relocation services, best moving companies, nationwide movers, professional movers, cross country moving companies, furniture removals,
moving costs, moving options, office movers, removal vans, professional packers and movers, nationwide moving companies, moving storage containers, full service movers, interstate moving companies, the movers, affordable movers,
relocation companies, home moving companies, moving men, find moving service, interstate movers, professional moving companies, moving estimate, top rated moving companies, state to state moving companies, full service moving companies,
moving and storage companies, state to state movers, american moving company, apartment movers, moving companies prices, local removal companies, professional packers, moving van lines, commercial movers,
find a mover, moving storage, best long distance moving companies, van lines, removal services, national removal companies, movers n packers, moving labor, packers and movers services, best movers, home moving services,
out of state movers, hire movers, best rated moving companies, moving company quotes, moving truck companies, house moving companies, moving van companies, american movers, packing services, household moving companies, national movers,
budget moving, office relocation, moving cost estimator, of state moving companies, cheapest moving company, house removal companies, best long distance movers, packing companies, furniture removal companies, house moving costs,
affordable moving companies, furniture moving companies, packers and movers near me, household movers, removal firms, moving companies in, van lines moving company,"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Intro
        pic={comP}
        introText={
          "Let our local moving company give you a lift to your new home"
        }
      />
      <div className="CFBC">
        <div className="CFB">
          <Fade top>
            <h2>Local Moving – We don’t just move furniture, we move lives</h2>
          </Fade>
          <Fade bottom>
            <p>
              Moving to a new home is a major life event – and that includes
              local moves. GVA residential movers understand that and want to
              make sure you feel at home before you even unpack a box.
            </p>
          </Fade>
        </div>
      </div>
      <div className="CSBCC">
        <div className="CSBC">
          <div className="CSBCT">
            <Fade left>
              <h3>Every move is important. No matter how far you go.</h3>
              <p>
                ABC New Movers makes your residential moving stress-free and
                easy! We relocate all your staff wherever “into” or “out” and
                whenever you need it. Call us right now for free estimates. We
                provide residential moving service for apartments, houses,
                buildings. We stand that any home is valued by the staff
                belongingness and we guarantee that all your values arrive to
                the new place at the same condition. Upon your request we
                disassemble oversize furniture, wrap it with special protective
                material and reassemble it at new place.
              </p>
            </Fade>
          </div>
          <div className="CSBCI">
            <Fade right>
              <img src={f1} alt="" />
            </Fade>
          </div>
        </div>
      </div>
      <div className="CTBCC">
        <div className="CTBC">
          <div className="CTBCT">
            <Fade right>
              <h3>We want you to move happy.</h3>
              <p>
                We understanding that moving can seem very overwhelming, and
                expensive. However, with ABC New Movers, residential moving is
                usually quoted according to the number of hours needed to
                complete the move, but sometimes a flat rate price can be
                provided during the quote. The overall cost of moving your
                household will depend on the volume and dimensions of the items
                that are being relocated, as well as the number of movers that
                will be necessary.
              </p>
            </Fade>
          </div>
          <div className="CTBCI">
            <Fade left>
              <img src={f2} alt="" />
            </Fade>
          </div>
        </div>
      </div>
      <TPics />
      <BottomPage />
    </div>
  );
};

export default LocalMov;
