import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import "./Intro.css";

const Intro = (props) => {
  const myStyle = {
    top: "0",
    left: "0",
    background:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" +
      props.pic +
      ") no-repeat center center ",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
  };

  return (
    <div className="containerIntro">
      <div style={myStyle} className="background">
        <Fade top>
          <div className="introText">
            <h1>{props.introText}</h1>
            <h2>{props.text}</h2>
          </div>
        </Fade>
        <div className="buttonIntro">
          <Fade left>
            <Link to="/request-quote" id="secondAI">
              GET A FREE QUOTE
            </Link>
          </Fade>
          <Fade right>
            <Link to="/book-consultation" id="firstAI">
              BOOK CONSULTATION
            </Link>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Intro;
