import React from "react";
import Fade from "react-reveal/Fade";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Radium, { StyleRoot } from "radium";
import Sliders from "../Sliders/Sliders";

import Intro from "../Intro/Intro";
import BottomPage from "../BottomWebsite/BottomWebsite";
import "./LocationControl.css";

const LocationControl = (props) => {
  const mapStyle = {
    width: "50% !important",
    height: "500px",

    "@media and (max-width: 600px)": {
      width: "70%",
    },
  };

  return (
    <StyleRoot>
      <div
        style={{
          width: "100% !important",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Intro
          introText={props.introText1}
          text={props.text1}
          pic={props.pic2}
        />
        <div className="locationsTextControl">
          <Fade top>
            <h3>{props.locationH3Text}</h3>
          </Fade>
          <Fade bottom>
            <p>{props.locationPText}</p>
          </Fade>
        </div>
        <div className="locationController">
          <div className="AddressC">
            <Fade left>
              <p>{props.address}</p>
            </Fade>
          </div>
          <Fade bottom>
            <div className="mapLocation">
              <Map
                className="mapStyle"
                google={props.google}
                zoom={15}
                style={mapStyle}
                initialCenter={{ lat: props.lat1, lng: props.lng1 }}
              >
                <Marker position={{ lat: props.lat1, lng: props.lng1 }} />
              </Map>
            </div>
          </Fade>
        </div>
        <Sliders />
        <BottomPage />
      </div>
    </StyleRoot>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBGz0QAFp2jGuvXQ2e753Cy8NjhgV2curA",
})(Radium(LocationControl));
