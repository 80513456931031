import React from "react";
import Fade from "react-reveal/Fade";
import "./Tpics.css";

import fPic from "./img/clock.png";
import sPic from "./img/like.png";
import tPic from "./img/mover.png";

const TPics = () => (
  <div className="tpC">
    <div className="fTP">
      <Fade left>
        <img src={fPic} alt="" />
        <h6>Timely Delivery</h6>
      </Fade>
    </div>
    <div className="sTP">
      <Fade bottom>
        <img src={sPic} alt="" />
        <h6>Caring, professional Staff</h6>
      </Fade>
    </div>
    <div className="tTP">
      <Fade right>
        <img src={tPic} alt="" />
        <h6>Satisfaction Guaranteed</h6>
      </Fade>
    </div>
  </div>
);

export default TPics;
