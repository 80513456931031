import React from "react";
import Fade1 from "react-reveal/Fade";
import { Slide } from "react-slideshow-image";
import { Link } from "react-router-dom";
import "./Sliders.css";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  pauseOnHover: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
};

const Sliders = props => {
  return (
    <div className="slide-container">
      <Fade1 bottom>
        <Slide {...properties}>
          <div className="each-slide">
            <div
              className="vanImg"
              style={{
                backgroundSize: "100% 100%"
              }}
            >
              <span>
                Vancouver
                <Link className="lButton" to="/locations/vancouver">
                  Move!
                </Link>
              </span>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="burImg"
              style={{
                //   background: `url(${slideImages[1]})`,
                backgroundSize: "initial"
              }}
            >
              <span>
                Burnaby
                <Link className="lButton" to="/locations/burnaby">
                  Move!
                </Link>
              </span>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="coqImg"
              style={{
                //   background: `url(${slideImages[2]})`,
                backgroundSize: "100% 100%"
              }}
            >
              <span>
                Coquitlam{" "}
                <Link className="lButton" to="/locations/coquitlam">
                  Move!
                </Link>
              </span>
            </div>
          </div>
          <div className="each-slide">
            <div
              className="nwImg"
              style={{
                //   background: `url(${slideImages[2]})`,
                backgroundSize: "100% 100%"
              }}
            >
              <span>
                New Westminster{" "}
                <Link className="lButton" to="/locations/newwestminster">
                  Move!
                </Link>
              </span>
            </div>
          </div>
        </Slide>
      </Fade1>
    </div>
  );
};

export default Sliders;
