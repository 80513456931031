import React from "react";
import "./SideMenuToggleButton.css";

const SideMenuToggleButton = props => (
  <button className="sideMenuButton" onClick={props.click}>
    <div className="sideButtonLine"></div>
    <div className="sideButtonLine"></div>
    <div className="sideButtonLine"></div>
  </button>
);

export default SideMenuToggleButton;
