import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import BottomPage from "../BottomWebsite/BottomWebsite";
import SweetAlert from "react-bootstrap-sweetalert";

import "./RequestQuote.css";

import logo from "./logo.png";

const initialState = {
  dateOfMove: "",
  movingFrom: "",
  movingTo: "",
  typeOfHome: "",
  fName: "",
  lName: "",
  phoneNumber: "",
  email: "",
  addInfo: "",
  popUp: false,
};

class RequestQuote extends Component {
  constructor() {
    super();

    this.state = initialState;

    this.state = {
      dateOfMove: "",
      movingFrom: "",
      movingTo: "",
      typeOfHome: "",
      fName: "",
      lName: "",
      phoneNumber: "",
      email: "",
      addInfo: "",
      popUp: false,
    };
  }

  onDateOfMoveChange = (event) => {
    this.setState({ dateOfMove: event.target.value });
  };

  onMovingFromChange = (event) => {
    this.setState({ movingFrom: event.target.value });
  };

  onMovingToChange = (event) => {
    this.setState({ movingTo: event.target.value });
  };

  onTypeOfHomeChange = (event) => {
    this.setState({ typeOfHome: event.target.value });
  };

  onFNameChange = (event) => {
    this.setState({ fName: event.target.value });
  };

  onLNameChange = (event) => {
    this.setState({ lName: event.target.value });
  };

  onPhoneNumberChange = (event) => {
    this.setState({ phoneNumber: event.target.value });
  };

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  onAddInfoChange = (event) => {
    this.setState({ addInfo: event.target.value });
  };

  onSubmitContactForm = (event) => {
    event.preventDefault();
    fetch("https://abcnewmove.herokuapp.com/sendRequestQuote", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        dateOfMove: this.state.dateOfMove,
        movingFrom: this.state.movingFrom,
        movingTo: this.state.movingTo,
        typeOfHome: this.state.typeOfHome,
        fName: this.state.fName,
        lName: this.state.lName,
        phoneNumber: this.state.phoneNumber,
        email: this.state.email,
        addInfo: this.state.addInfo,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  sendConfirmEmail = (event) => {
    fetch("https://abcnewmove.herokuapp.com/confirmEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.email,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  onClick = (event) => {
    this.setState({ popUp: true });
    this.onSubmitContactForm(event);
    this.sendConfirmEmail(event);
  };

  render() {
    return (
      <div>
        <div className="RQcontainer">
          <Fade>
            <img src={logo} alt="" />
          </Fade>
        </div>
        <div className="RQtopPage">
          <Fade top>
            <h1>Request A Quote</h1>
            <p>
              Fill out the simple form below to request a free quote from our
              ABC New Movers movers with no obligation.
            </p>
          </Fade>
        </div>
        <Fade bottom>
          <div className="RQform">
            <div className="RQFormControl">
              <form onSubmit={this.onClick}>
                <label htmlFor="dateOfMove">Date of Move:</label>
                <br></br>
                <input
                  style={{ paddingLeft: "30px" }}
                  className="dateS"
                  type="date"
                  id="dateOfMove"
                  placeholder="yyyy/mm/dd"
                  onChange={this.onDateOfMoveChange}
                  value={this.state.dateOfMove}
                  required
                />
                <br></br>
                <label htmlFor="movingFrom">Moving from:</label>
                <br></br>
                <input
                  style={{ paddingLeft: "30px" }}
                  className="location"
                  type="text"
                  id="movingFrom"
                  placeholder="zip, city or address"
                  onChange={this.onMovingFromChange}
                  value={this.state.movingFrom}
                  required
                />
                <br></br>
                <label htmlFor="movingTo">Moving to:</label>
                <br></br>
                <input
                  style={{ paddingLeft: "30px" }}
                  className="location"
                  type="text"
                  id="movingTo"
                  placeholder="zip, city or address"
                  onChange={this.onMovingToChange}
                  value={this.state.movingTo}
                  required
                />
                <br></br>
                <label htmlFor="typeOfHome">Home Size:</label>
                <select
                  name="typeOfHome"
                  id="typeOfHome"
                  onChange={this.onTypeOfHomeChange}
                  value={this.state.typeOfHome}
                  required
                >
                  <option value="">select</option>
                  <option value="">Select Home Size</option>
                  <option value="Studio (350 – 600 SF)">
                    Studio (350 – 600 SF)
                  </option>
                  <option value="1 Bedroom (600 – 900 SF)">
                    1 Bedroom (600 – 900 SF)
                  </option>
                  <option value="2 Bedroom (900 – 1300 SF)">
                    2 Bedroom (900 – 1300 SF)
                  </option>
                  <option value="3 Bedroom (1300 – 2000 SF)">
                    3 Bedroom (1300 – 2000 SF)
                  </option>
                  <option value="4 Bedroom">4 Bedroom</option>
                  <option value="Office">Office</option>
                  <option value="Other">Other</option>
                </select>
                <h2>Contact Information</h2>
                <label htmlFor="fName">First Name:</label>
                <br></br>
                <input
                  className=""
                  type="text"
                  id="fName"
                  placeholder="First Name"
                  onChange={this.onFNameChange}
                  value={this.state.fName}
                  required
                />
                <br></br>
                <label htmlFor="lName">Last Name:</label>
                <br></br>
                <input
                  className=""
                  type="text"
                  id="lName"
                  placeholder="Last Name"
                  onChange={this.onLNameChange}
                  value={this.state.lName}
                  required
                />
                <br></br>
                <label htmlFor="phoneNumber">Phone Number:</label>
                <br></br>
                <input
                  className=""
                  type="text"
                  id="lName"
                  placeholder="Phone Number"
                  onChange={this.onPhoneNumberChange}
                  value={this.state.phoneNumber}
                  required
                />
                <br></br>
                <label htmlFor="email">Email:</label>
                <br></br>
                <input
                  className=""
                  type="email"
                  id="lName"
                  placeholder="Email"
                  onChange={this.onEmailChange}
                  value={this.state.email}
                  required
                />
                <br></br>
                <label htmlFor="addInfo">Additional Information:</label>
                <br></br>
                <input
                  className="addInfoC"
                  type="text"
                  id="addInfo"
                  placeholder="Additional Information"
                  onChange={this.onAddInfoChange}
                  value={this.state.addInfo}
                  required
                />
                <br></br>
                <div className="cBf">
                  <button id="secondAA" type="submit">
                    {" "}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Fade>
        <SweetAlert
          show={this.state.popUp}
          success
          title="Mail has been sent!"
          onConfirm={() => this.setState(initialState)}
        />

        <BottomPage />
      </div>
    );
  }
}

export default RequestQuote;
