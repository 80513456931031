import React from "react";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";

import Intro from "../Intro/Intro";
import "./Commercial.css";
import BottomPage from "../BottomWebsite/BottomWebsite";
import TPics from "../TPics/TPics";

import comP from "./img/comr.jpg";
import f1 from "./img/f1.jpg";
import f2 from "./img/f2.jpg";

const Commercial = (props) => {
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABC New Movers - Moving is Simple!</title>
        <meta
          name="description"
          content="ABC New Move offers reliable residential, commercial moving, delivery and storage services in Metro Vancouver, British Columbia"
        />
        <meta
          name="keywords"
          content="abc new move, abcnewmoving,  moving, mover, company, storage, depot, movers, urgent, transportation, track, last minute moving, depository,
      reliable, service, home, apartment, condo, delivery, pick up, phone, free quote, BC, British Columbia, residential,  commercial salvatory, lower mainland, Vancouver,
      North Vancouver, West Vancouver, Moving Canada BC, moving, move, mover, storage, residential,  commercial , depot, movers, urgent moving,  last minute moving, transportation, 
      track, last minute moving,  depository, reliable, service, North Vancouver, Coquitlam,  moving track, moving companies movers, packers and movers, removal companies, moving services, moving companies near me, movers near me,furniture movers,
long distance movers, national moving companies, moving containers, moving vans, moving help, removals, long distance moving companies, moving quotes, local movers, the moving company, house movers, house removals,
removal van, cross country movers, packers & movers, local moving companies, moving van, relocation services, best moving companies, nationwide movers, professional movers, cross country moving companies, furniture removals,
moving costs, moving options, office movers, removal vans, professional packers and movers, nationwide moving companies, moving storage containers, full service movers, interstate moving companies, the movers, affordable movers,
relocation companies, home moving companies, moving men, find moving service, interstate movers, professional moving companies, moving estimate, top rated moving companies, state to state moving companies, full service moving companies,
moving and storage companies, state to state movers, american moving company, apartment movers, moving companies prices, local removal companies, professional packers, moving van lines, commercial movers,
find a mover, moving storage, best long distance moving companies, van lines, removal services, national removal companies, movers n packers, moving labor, packers and movers services, best movers, home moving services,
out of state movers, hire movers, best rated moving companies, moving company quotes, moving truck companies, house moving companies, moving van companies, american movers, packing services, household moving companies, national movers,
budget moving, office relocation, moving cost estimator, of state moving companies, cheapest moving company, house removal companies, best long distance movers, packing companies, furniture removal companies, house moving costs,
affordable moving companies, furniture moving companies, packers and movers near me, household movers, removal firms, moving companies in, van lines moving company,"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Intro pic={comP} introText={`If work is stressful, Moving won't be`} />
      <div className="CFBC">
        <div className="CFB">
          <Fade top>
            <h2>ABC New Movers – we’ll make your business move successful.</h2>
          </Fade>
          <Fade bottom>
            <p>
              Whether you’re a local business or a national company, ABC New
              Movers commercial movers have what you need to move the right way.
            </p>
          </Fade>
        </div>
      </div>
      <div className="CSBCC">
        <div className="CSBC">
          <div className="CSBCT">
            <Fade left>
              <h3>
                Commercial movers in Vancouver will make your moving experience
                a great one.
              </h3>
              <p>
                We offer reliable commercial moving services in all areas around
                Greater Vancouver. This includes our professional loading and
                unloading services such as arranging your belongings where you
                want them and assembling them as needed. ABC New Movers will
                relocate your commercial company with the utmost care for your
                equipment, office belongings etc.
              </p>
            </Fade>
          </div>
          <div className="CSBCI">
            <Fade right>
              <img src={f1} alt="" />
            </Fade>
          </div>
        </div>
      </div>
      <div className="CTBCC">
        <div className="CTBC">
          <div className="CTBCT">
            <Fade right>
              <h3>
                Office movers in Vancouver – You do the business, we do the
                moving.
              </h3>
              <p>
                You have so many responsibilities at work, from managing
                employees, to servicing clients, to coming up with the next
                great plan of action. Let our office movers take you to your new
                home so you can stay focused on your business. ABC New Movers
                Moving is a professional and experienced moving service that has
                been doing this for years!
              </p>
            </Fade>
          </div>
          <div className="CTBCI">
            <Fade left>
              <img src={f2} alt="" />
            </Fade>
          </div>
        </div>
      </div>
      <TPics />
      <BottomPage />
    </div>
  );
};

export default Commercial;
