import React from "react";
import Reviews from "./Reviews/Reviews";
import Links from "./Links/Links";
import Footer from "../Footer/Footer";
import Fade from "react-reveal/Fade";

import "./BottomWebsite.css";

import fLogo from "./img/angies.png";
import sLogo from "./img/logos2.png";
import tLogo from "./img/thumbtack.png";
import truckPic from "./img/truck.png";

const BottomWebsite = props => {
  return (
    <div className="BWcontainer">
      <div className="BWhpWrap">
        <div className="BWhpcontrol">
          <Fade top>
            <h5>#1 GVA MOVING COMPANY</h5>
            <h6>#1 RATED GVA MOVERS</h6>
          </Fade>
          <Fade bottom>
            <p>
              With ABC New Movers experienced team of GVA movers take pride in
              arranging a seamless move for your household or business, making
              the transition fast and efficient. Our mission is to make your
              move stress free!
            </p>
          </Fade>
        </div>
      </div>
      <div className="BWcontainer1">
        <div className="BWcontainer2">
          <div className="BWimages">
            <Fade left>
              <img src={fLogo} alt="" />
              <img src={sLogo} alt="" />
              <img src={tLogo} alt="" />
            </Fade>
          </div>
          <div className="IMGDIV">
            <Fade right>
              <img id="truckIMG" src={truckPic} alt="" />
            </Fade>
          </div>
        </div>
      </div>
      <Reviews />
      <Links />
      <Footer />
    </div>
  );
};

export default BottomWebsite;
