import React from "react";
import { Helmet } from "react-helmet";
import "./About.css";
import BottomPage from "../BottomWebsite/BottomWebsite";
import Fade from "react-reveal/Fade";

import p1 from "./img/mover.png";
import p2 from "./img/clock.png";
import p3 from "./img/like.png";
import truckVideo from "./video/truck.mp4";

const About = (props) => {
  return (
    <div className="mainContainerA">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABC New Movers - Moving is Simple!</title>
        <meta
          name="description"
          content="ABC New Move offers reliable residential, commercial moving, delivery and storage services in Metro Vancouver, British Columbia"
        />
        <meta
          name="keywords"
          content="abc new move, abcnewmoving,  moving, mover, company, storage, depot, movers, urgent, transportation, track, last minute moving, depository,
      reliable, service, home, apartment, condo, delivery, pick up, phone, free quote, BC, British Columbia, residential,  commercial salvatory, lower mainland, Vancouver,
      North Vancouver, West Vancouver, Moving Canada BC, moving, move, mover, storage, residential,  commercial , depot, movers, urgent moving,  last minute moving, transportation, 
      track, last minute moving,  depository, reliable, service, North Vancouver, Coquitlam,  moving track, moving companies movers, packers and movers, removal companies, moving services, moving companies near me, movers near me,furniture movers,
long distance movers, national moving companies, moving containers, moving vans, moving help, removals, long distance moving companies, moving quotes, local movers, the moving company, house movers, house removals,
removal van, cross country movers, packers & movers, local moving companies, moving van, relocation services, best moving companies, nationwide movers, professional movers, cross country moving companies, furniture removals,
moving costs, moving options, office movers, removal vans, professional packers and movers, nationwide moving companies, moving storage containers, full service movers, interstate moving companies, the movers, affordable movers,
relocation companies, home moving companies, moving men, find moving service, interstate movers, professional moving companies, moving estimate, top rated moving companies, state to state moving companies, full service moving companies,
moving and storage companies, state to state movers, american moving company, apartment movers, moving companies prices, local removal companies, professional packers, moving van lines, commercial movers,
find a mover, moving storage, best long distance moving companies, van lines, removal services, national removal companies, movers n packers, moving labor, packers and movers services, best movers, home moving services,
out of state movers, hire movers, best rated moving companies, moving company quotes, moving truck companies, house moving companies, moving van companies, american movers, packing services, household moving companies, national movers,
budget moving, office relocation, moving cost estimator, of state moving companies, cheapest moving company, house removal companies, best long distance movers, packing companies, furniture removal companies, house moving costs,
affordable moving companies, furniture moving companies, packers and movers near me, household movers, removal firms, moving companies in, van lines moving company,"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="mediaWrap">
        <Fade top>
          <h2>There’s ABC New Movers, and the wrong way.</h2>
        </Fade>
        <div className="mediaControlA">
          <div className="aBoxA">
            <Fade bottom>
              <video controls>
                <source src={truckVideo} />
                Your browser does not support the video tag.
              </video>
            </Fade>
          </div>
        </div>
      </div>
      <div className="aSBlock">
        <div className="aSBlockT">
          <Fade top>
            <h3>
              Find out why ABC New Movers is rated the #1 moving company in
              Greater Vancouver Area.
            </h3>
          </Fade>
          <Fade bottom>
            <p>
              ABC New Movers is a fully licensed moving company registered in
              British Columbia. We provide moving services in GVA. ABC New
              Movers operates our own fully equipped moving trucks. ABC New
              Movers provides all moving supplies and accessories upon request.
            </p>
          </Fade>
        </div>
      </div>
      <div className="aTBlock">
        <div className="aTBlockB">
          <div>
            <Fade left>
              <img src={p1} alt="" />
            </Fade>
          </div>
          <div className="aTBlockText">
            <Fade right>
              <h3>Caring, professional Staff</h3>
              <p>
                ABC New Movers - Moving is Simple! We know how stressful moving
                day can be - but it doesn’t have to be. Every one of our
                employees is highly trained and cares about the happiness of our
                customers. From our movers in GVA to our operations team to our
                sales staff, we have the finest people in the moving and storage
                business, making us one of the best residential moving companies
                and office moving companies.
              </p>
            </Fade>
          </div>
        </div>
        <div className="aTBlockB">
          <div>
            <Fade left>
              <img src={p2} alt="" />
            </Fade>
          </div>
          <div className="aTBlockText">
            <Fade right>
              <h3>Timely Delivery</h3>
              <p>
                ABC New Movers respects your time. Our GVA movers show up on
                time. We deliver on time. We believe we are only as good as our
                word. So if we say we will be at your home or office ready to
                move at 3pm on Tuesday, then that’s when we’ll be there. Our
                movers in GVA treat our customers the way we would like to be
                treated.
              </p>
            </Fade>
          </div>
        </div>
        <div className="aTBlockB">
          <div>
            <Fade left>
              <img src={p3} alt="" />
            </Fade>
          </div>
          <div className="aTBlockText">
            <Fade right>
              <h3>Satisfaction Guaranteed</h3>
              <p>
                As the top rated GVA moving company, ABC New Movers guarantees
                your satisfaction with every move and we put it in writing. ABC
                New Movers Guarantee gives you the peace of mind that comes from
                knowing there is a full customer service department behind you
                that will not leave any issue unresolved.
              </p>
            </Fade>
          </div>
        </div>
      </div>
      <BottomPage />
    </div>
  );
};

export default About;
