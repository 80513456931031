import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import SweetAlert from "react-bootstrap-sweetalert";
import BottomPage from "../BottomWebsite/BottomWebsite";
import "./Comment.css";

const initialState = {
  fName: "",
  lName: "",
  email: "",
  topic: "",
  message: "",
  popUp: false,
};

class Comment extends Component {
  constructor(props) {
    super();
    this.state = initialState;

    this.state = {
      fName: "",
      lName: "",
      email: "",
      topic: "",
      message: "",
      popUp: false,
    };
  }

  onFNameChange = (event) => {
    this.setState({ fName: event.target.value });
  };

  onLNameChange = (event) => {
    this.setState({ lName: event.target.value });
  };

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  onTopicChange = (event) => {
    this.setState({ topic: event.target.value });
  };

  onMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  onSubmitContactForm = (event) => {
    event.preventDefault();
    fetch("https://abcnewmove.herokuapp.com/sendContactEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fName: this.state.fName,
        lName: this.state.lName,
        email: this.state.email,
        topic: this.state.topic,
        message: this.state.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  sendConfirmEmail = (event) => {
    fetch("https://abcnewmove.herokuapp.com/confirmEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.email,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  onClick = (event) => {
    this.setState({ popUp: true });
    this.onSubmitContactForm(event);
    this.sendConfirmEmail(event);
  };

  render() {
    return (
      <div className="commentContainer">
        <div className="commentIntro">
          <div className="commentIntroText">
            <Fade left>
              <h2>ABC New Movers</h2>
            </Fade>
            <Fade right>
              <p>A tradition in excellence!</p>
            </Fade>
          </div>
        </div>
        <div className="formWrapControl">
          <div className="formTextControl">
            <Fade top>
              <h3>Help us improve</h3>
            </Fade>
            <Fade bottom>
              <p>
                We welcome the chance to hear from our clients and improve our
                services, or just to leave a compliment. Please fill out the
                form below and let us know how we can continue to be your #1
                movers, GVA!
              </p>
            </Fade>
          </div>
          <Fade bottom>
            <div className="formController">
              <h3>Contact Information</h3>
              <form onSubmit={this.onClick}>
                <label htmlFor="fName">First name:</label>
                <br></br>
                <input
                  type="text"
                  id="fName"
                  name="fName"
                  placeholder="First Name"
                  onChange={this.onFNameChange}
                  value={this.state.fName}
                  required
                />
                <br></br>
                <label htmlFor="lName">Last name:</label>
                <br></br>
                <input
                  type="text"
                  id="lName"
                  name="lName"
                  placeholder="Last name"
                  onChange={this.onLNameChange}
                  value={this.state.lName}
                  required
                />
                <br></br>
                <label htmlFor="email">Email:</label>
                <br></br>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={this.onEmailChange}
                  value={this.state.email}
                  required
                />
                <br></br>
                <label htmlFor="topic">Topic:</label>
                <br></br>
                <select
                  id="topic"
                  required
                  placeholder="Select"
                  onChange={this.onTopicChange}
                  value={this.state.topic}
                >
                  <option value="">select</option>
                  <option value="Pickup/Delivery">Pickup/Delivery</option>
                  <option value="Missing/Damage">Missing/Damage</option>
                  <option value="Billing">Billing</option>
                  <option value="Compliment">Compliment</option>
                  <option value="Other">Other</option>
                </select>
                <br></br>
                <br></br>
                <label htmlFor="message">Message:</label>
                <br></br>
                <input
                  style={{ height: "100px" }}
                  type="text"
                  id="message"
                  name="message"
                  onChange={this.onMessageChange}
                  value={this.state.message}
                  required
                />
                <br></br>
                <div className="cBf">
                  <button style={{ cursor: "pointer" }} id="secondAA">
                    {" "}
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Fade>
          <SweetAlert
            show={this.state.popUp}
            success
            title="Mail has been sent!"
            onConfirm={() => this.setState(initialState)}
          />
        </div>
        <BottomPage />
      </div>
    );
  }
}

export default Comment;
