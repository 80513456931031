import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import SweetAlert from "react-bootstrap-sweetalert";
import BottomPage from "../BottomWebsite/BottomWebsite";
import "./Consultation.css";

const initialState = {
  typeOfHome: "",
  dateOfMove: "",
  whenToMove: "",
  streetAddressFrom: "",
  aptFrom: "",
  cityFrom: "",
  zipFrom: "",
  streetAddressTo: "",
  aptTo: "",
  cityTo: "",
  zipTo: "",
  fName: "",
  lName: "",
  email: "",
  phoneNumber: "",
  dateOfAv: "",
  startTime: "",
  popUp: false,
};

class Consultation extends Component {
  constructor() {
    super();
    this.state = initialState;
  }

  onTypeOfHomeChange = (event) => {
    this.setState({ typeOfHome: event.target.value });
  };

  onDateOfMoveChange = (event) => {
    this.setState({ dateOfMove: event.target.value });
  };

  onWhenToMoveChange = (event) => {
    this.setState({ whenToMove: event.target.value });
  };

  onStreetAddressFromChange = (event) => {
    this.setState({ streetAddressFrom: event.target.value });
  };

  onAptFromChange = (event) => {
    this.setState({ aptFrom: event.target.value });
  };

  onCityFromChange = (event) => {
    this.setState({ cityFrom: event.target.value });
  };

  onZipFromChange = (event) => {
    this.setState({ zipFrom: event.target.value });
  };

  onStreetAddressToChange = (event) => {
    this.setState({ streetAddressTo: event.target.value });
  };

  onAptToChange = (event) => {
    this.setState({ aptTo: event.target.value });
  };

  onCityToChange = (event) => {
    this.setState({ cityTo: event.target.value });
  };

  onZipToChange = (event) => {
    this.setState({ zipTo: event.target.value });
  };

  onFNameChange = (event) => {
    this.setState({ fName: event.target.value });
  };

  onLNameChange = (event) => {
    this.setState({ lName: event.target.value });
  };

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  onPhoneNumberChange = (event) => {
    this.setState({ phoneNumber: event.target.value });
  };

  onDateOfAvChange = (event) => {
    this.setState({ dateOfAv: event.target.value });
  };

  onStartTimeChange = (event) => {
    this.setState({ startTime: event.target.value });
  };

  onSubmitContactForm = (event) => {
    event.preventDefault();
    fetch("https://abcnewmove.herokuapp.com/bookCons", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        typeOfHome: this.state.typeOfHome,
        dateOfMove: this.state.dateOfMove,
        whenToMove: this.state.whenToMove,
        streetAddressFrom: this.state.streetAddressFrom,
        aptFrom: this.state.aptFrom,
        cityFrom: this.state.cityFrom,
        zipFrom: this.state.zipFrom,
        streetAddressTo: this.state.streetAddressTo,
        aptTo: this.state.aptTo,
        cityTo: this.state.cityTo,
        zipTo: this.state.zipTo,
        fName: this.state.fName,
        lName: this.state.lName,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        dateOfAv: this.state.dateOfAv,
        startTime: this.state.startTime,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  sendConfirmEmail = (event) => {
    fetch("https://abcnewmove.herokuapp.com/confirmEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.email,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  onClick = (event) => {
    this.setState({ popUp: true });
    this.onSubmitContactForm(event);
    this.sendConfirmEmail(event);
  };

  render() {
    return (
      <div className="consultationContainer">
        <div className="h2Cons">
          <div>
            <Fade>
              <h2>Schedule an onsite consult from a ABC New Movers expert</h2>
            </Fade>
          </div>
        </div>
        <div className="consultationForm">
          <form onSubmit={this.onClick}>
            <Fade top>
              <h3>What are you moving?</h3>
              <label style={{ paddingRight: "30px" }} htmlFor="typeOfHome">
                Select Home Size:
              </label>
              <select
                name="typeOfHome"
                id="typeOfHome"
                required
                onChange={this.onTypeOfHomeChange}
                value={this.state.typeOfHome}
              >
                <option value="">Select Home Size</option>
                <option value="Studio (350 – 600 SF)">
                  Studio (350 – 600 SF)
                </option>
                <option value="1 Bedroom (600 – 900 SF)">
                  1 Bedroom (600 – 900 SF)
                </option>
                <option value="2 Bedroom (900 – 1300 SF)">
                  2 Bedroom (900 – 1300 SF)
                </option>
                <option value="3 Bedroom (1300 – 2000 SF)">
                  3 Bedroom (1300 – 2000 SF)
                </option>
                <option value="4 Bedroom">4 Bedroom</option>
                <option value="Office">Office</option>
                <option value="Other">Other</option>
              </select>
              <h3>When and where are you moving?</h3>
              <label style={{ paddingRight: "30px" }} htmlFor="dateOfMove">
                Moving Date:
              </label>
              <input
                style={{ paddingLeft: "30px", marginRight: "30px" }}
                className="dateS"
                type="date"
                id="dateOfMove"
                placeholder="yyyy/mm/dd"
                onChange={this.onDateOfMoveChange}
                value={this.state.dateOfMove}
                required
              />
              <select
                name="dateOfMove"
                id="whenToMove"
                onChange={this.onWhenToMoveChange}
                value={this.state.whenToMove}
                required
              >
                <option value="">Select start time</option>
                <option value="morning">Morning</option>
                <option value="afternoon">Afternoon</option>
              </select>
            </Fade>
            <div className="forms2">
              <Fade left>
                <div className="movingFrom">
                  <h3>Moving From</h3>
                  <label htmlFor="streetAddressFrom">Street Address:</label>
                  <br />
                  <input
                    type="text"
                    id="streetAddressFrom"
                    name="streetAddressFrom"
                    onChange={this.onStreetAddressFromChange}
                    value={this.state.streetAddressFrom}
                    required
                  />{" "}
                  <br />
                  <label htmlFor="aptFrom">Apt #:</label>
                  <br />
                  <input
                    type="text"
                    id="aptFrom"
                    name="aptFrom"
                    onChange={this.onAptFromChange}
                    value={this.state.aptFrom}
                    placeholder="Leave empty if you live in the house"
                  />
                  <br />
                  <label htmlFor="cityFrom">City:</label>
                  <br />
                  <input
                    type="text"
                    id="cityFrom"
                    name="cityFrom"
                    onChange={this.onCityFromChange}
                    value={this.state.cityFrom}
                    required
                  />{" "}
                  <br />
                  <label htmlFor="zipFrom">Zip:</label> <br />
                  <input
                    type="text"
                    id="zipFrom"
                    name="zipFrom"
                    onChange={this.onZipFromChange}
                    value={this.state.zipFrom}
                    required
                  />{" "}
                  <br />
                </div>
              </Fade>
              <Fade right>
                <div className="movingTo">
                  {" "}
                  <h3>Moving To</h3>
                  <label htmlFor="streetAddressTo">Street Address:</label>
                  <br />
                  <input
                    type="text"
                    id="streetAddressTo"
                    name="streetAddressTo"
                    onChange={this.onStreetAddressToChange}
                    value={this.state.streetAddressTo}
                    required
                  />{" "}
                  <br />
                  <label htmlFor="aptTo">Apt #:</label>
                  <br />
                  <input
                    type="text"
                    id="aptTo"
                    name="aptTo"
                    onChange={this.onAptToChange}
                    value={this.state.aptTo}
                    placeholder="Leave empty if you move to the house"
                  />
                  <br />
                  <label htmlFor="cityTo">City:</label>
                  <br />
                  <input
                    type="text"
                    id="cityTo"
                    name="cityTo"
                    onChange={this.onCityToChange}
                    value={this.state.cityTo}
                    required
                  />{" "}
                  <br />
                  <label htmlFor="zipTo">Zip:</label> <br />
                  <input
                    type="text"
                    id="zipTo"
                    name="zipTo"
                    onChange={this.onZipToChange}
                    value={this.state.zipTo}
                    required
                  />{" "}
                  <br />
                </div>
              </Fade>
            </div>
            <Fade bottom>
              <h3>How can we reach you?</h3>
              <div className="form3">
                <label htmlFor="fName" style={{ textAlign: "left" }}>
                  First name:
                </label>
                <br />
                <input
                  type="text"
                  id="fName"
                  name="fName"
                  placeholder="First Name"
                  onChange={this.onFNameChange}
                  value={this.state.fName}
                  required
                />
                <br />
                <label htmlFor="lName">Last name:</label>
                <br />
                <input
                  type="text"
                  id="lName"
                  name="lName"
                  placeholder="Last name"
                  onChange={this.onLNameChange}
                  value={this.state.lName}
                  required
                />
                <br />
                <label htmlFor="email">Email:</label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={this.onEmailChange}
                  value={this.state.email}
                  required
                />
                <br />
                <label htmlFor="phoneNumber">Phone Number:</label>
                <br />
                <input
                  type="phoneNumber"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  onChange={this.onPhoneNumberChange}
                  value={this.state.phoneNumber}
                  required
                />
              </div>
              <h3>When are you available for a consultation?</h3>
              <div className="consBottom">
                <label style={{ paddingRight: "30px" }} htmlFor="dateOfAv">
                  Date:
                </label>
                <input
                  style={{ paddingLeft: "30px", marginRight: "30px" }}
                  className="dateS"
                  type="date"
                  id="dateOfAv"
                  placeholder="yyyy/mm/dd"
                  onChange={this.onDateOfAvChange}
                  value={this.state.dateOfAv}
                  required
                />
                <label style={{ paddingRight: "30px" }} htmlFor="startTime">
                  Select start time:
                </label>
                <select
                  name="startTime"
                  id="startTime"
                  onChange={this.onStartTimeChange}
                  value={this.state.startTime}
                  required
                >
                  <option value="">Select start time</option>
                  <option value="8-10am">8-10am</option>
                  <option value="10am-12pm">10am-12pm</option>
                  <option value="12-2pm">12-2pm</option>
                  <option value="2-4pm">2-4pm</option>
                  <option value="4-6pm">4-6pm</option>
                  <option value="6-7pm">6-7pm</option>
                </select>
              </div>
              <div className="cBf">
                <button
                  style={{ cursor: "pointer" }}
                  id="secondAA"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Fade>
            <SweetAlert
              show={this.state.popUp}
              success
              title="Mail has been sent!"
              onConfirm={() => this.setState(initialState)}
            />
          </form>
        </div>
        <BottomPage />
      </div>
    );
  }
}

export default Consultation;
