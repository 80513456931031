import React from "react";
import { Link } from "react-router-dom";

import SideMenuToggleButton from "../SideMenu/SideMenuToggleButton";
import "./Navigation.css";

import phone from "./phone.png";

const Navigation = (props) => {
  return (
    <header className="navBar_header">
      <nav className="navBar_navigation">
        <div>
          <SideMenuToggleButton click={props.sideMenuClickHandler} />
        </div>
        <div className="navBar_Logo">
          <Link to="/">
            <img src={props.logo} alt="" height="70" />
          </Link>
        </div>
        <div className="phoneDiv">
          <img src={phone} alt="" height="30" />
          <p>
            <a style={{ textDecoration: "none" }} href="tel:+16047272210">
              604-727-2210
            </a>{" "}
            <br></br>{" "}
            <a style={{ textDecoration: "none" }} href="tel:+17788271032">
              778-827-1032
            </a>
          </p>
        </div>
        <div className="spacer" />
        <div className="navBar_items">
          <ul>
            <li>
              <Link to="/comment" id="firstA">
                COMMENT/COMPLAINT
              </Link>
            </li>
            <li>
              <Link to="/contact" id="secondA">
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
