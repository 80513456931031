import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import SweetAlert from "react-bootstrap-sweetalert";
import BottomPage from "../BottomWebsite/BottomWebsite";
import "./Careers.css";

import jobPic from "./job.jpg";

const initialState = {
  fName: "",
  lName: "",
  email: "",
  phoneNumber: "",
  message: "",
  popUp: false,
};

class Careers extends Component {
  constructor() {
    super();

    this.state = initialState;
  }

  onFNameChange = (event) => {
    this.setState({ fName: event.target.value });
  };

  onLNameChange = (event) => {
    this.setState({ lName: event.target.value });
  };

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  onPhoneNumberChange = (event) => {
    this.setState({ phoneNumber: event.target.value });
  };

  onMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  onSubmitCareerForm = (event) => {
    event.preventDefault();
    fetch("https://abcnewmove.herokuapp.com/careerForm", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fName: this.state.fName,
        lName: this.state.lName,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        message: this.state.message,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  sendConfirmEmail = (event) => {
    fetch("https://abcnewmove.herokuapp.com/confirmEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.email,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  onClick = (event) => {
    this.setState({ popUp: true });
    this.onSubmitCareerForm(event);
    this.sendConfirmEmail(event);
  };

  render() {
    return (
      <div className="careersWrap">
        <div className="careersContainer">
          <div>
            <Fade>
              <h2>Career Opportunities</h2>
            </Fade>
          </div>
        </div>
        <div className="careersMainWrap">
          <div className="careersMainContainer">
            <div className="careersText">
              <Fade left>
                <img src={jobPic} alt="" />
                <p>
                  ABC New Movers constantly growing company is looking for new
                  movers and general labors for residential and commercial
                  moving services in Greater Vancouver, BC If you are a positive
                  person and want to work in a friendly team of professional
                  movers please apply! It’s a great opportunity for those who
                  are looking for a job that will allow them to indulge in a
                  friendly atmosphere and express live attitude through their
                  work. If you are interested, please email or call us with our
                  short resume. Your wage will be determined based on your work
                  experience. Please note that only those considered for an
                  interview will be contacted.
                </p>
              </Fade>
            </div>
            <div className="careersForm">
              <Fade right>
                <form onSubmit={this.onClick}>
                  <label htmlFor="fName">First name:</label>
                  <br />
                  <input
                    type="text"
                    id="fName"
                    name="fName"
                    placeholder="First Name"
                    onChange={this.onFNameChange}
                    required
                  />
                  <br />
                  <label htmlFor="lName">Last name:</label>
                  <br />
                  <input
                    type="text"
                    id="lName"
                    name="lName"
                    placeholder="Last name"
                    onChange={this.onLNameChange}
                    required
                  />
                  <br />
                  <label htmlFor="email">Email:</label>
                  <br />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={this.onEmailChange}
                    required
                  />
                  <br />
                  <label htmlFor="phoneNumber">Phone Number:</label>
                  <br />
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    onChange={this.onPhoneNumberChange}
                    required
                  />
                  <br />
                  <label htmlFor="message">Message:</label>
                  <br />
                  <input
                    style={{ height: "100px" }}
                    type="text"
                    id="message"
                    name="message"
                    placeholder="Write about your work experience"
                    onChange={this.onMessageChange}
                    required
                  />
                  <br />
                  <div className="cBf">
                    <button style={{ cursor: "pointer" }} id="secondAA">
                      Submit
                    </button>
                  </div>
                </form>
              </Fade>
            </div>
            <SweetAlert
              show={this.state.popUp}
              success
              title="Mail has been sent!"
              onConfirm={() => this.setState(initialState)}
            />
          </div>
        </div>
        <BottomPage />
      </div>
    );
  }
}

export default Careers;
