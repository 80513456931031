import React from "react";
import Fade from "react-reveal/Fade";
import Collapsible from "react-collapsible";
import BottomPage from "../BottomWebsite/BottomWebsite";
import Intro from "../Intro/Intro";
import "./Faq.css";

import faqP from "./FAQ.png";

const Faq = props => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Intro pic={faqP} introText={"Moving Q&A - Ask us anything."} />
      <Fade bottom>
        <div className="faqControl">
          <Collapsible
            contentContainerTagName="test"
            trigger="How much in advance should I book your movers?"
          >
            <div className="faqBox">
              <p>
                The earlier the better. An earlier arrangement helps avoid
                double booking issues. Please note, as a moving company we are
                quite busy at a beginning and the end of each month.{" "}
              </p>
            </div>
          </Collapsible>
          <Collapsible
            contentContainerTagName="test"
            trigger="What type of payments do you accept?"
          >
            <div className="faqBox">
              <p>
                We accept all major credit cards like VISA, Master Card, and
                American Express. Cash and Certified Cheques are also welcomed.
              </p>
            </div>
          </Collapsible>
          <Collapsible
            contentContainerTagName="test"
            trigger="How can I book a moving arrangement with you?"
          >
            {" "}
            <div className="faqBox">
              <p>
                To book our service just call or text us at 604-727-2210 or
                778-819-1015. You can also email us at abcnewmovers@gmail.com
              </p>
            </div>
          </Collapsible>
          <Collapsible
            contentContainerTagName="test"
            trigger="Will you pack my belongings for the moving?"
          >
            {" "}
            <div className="faqBox">
              <p>
                Yes, our movers can come at an arranged time and pack your
                belongings in advance.
              </p>
            </div>
          </Collapsible>
          <Collapsible
            contentContainerTagName="test"
            trigger="Does ABC New Move provide moving trucks?"
          >
            {" "}
            <div className="faqBox">
              <p>
                Yes, we operate our own fully equipped moving trucks, so you
                don’t need to make separate arrangements with another company.
                Please contact our operators for more information.
              </p>
            </div>
          </Collapsible>
          <Collapsible
            contentContainerTagName="test"
            trigger="How will my furniture be protected during the move?"
          >
            {" "}
            <div className="faqBox">
              <p>
                All of our trucks are equipped with special moving racks, which
                we use to fix your furniture during the transportation. We also
                use special moving blankets to envelop and protect your
                belongings.
              </p>
            </div>
          </Collapsible>
          <Collapsible
            contentContainerTagName="test"
            trigger="Do you sell moving supplies?"
          >
            {" "}
            <div className="faqBox">
              <p>
                Yes, we sell a wide range of moving supplies such as moving
                boxes, tape, packing paper and more!
              </p>
            </div>
          </Collapsible>
          <Collapsible
            contentContainerTagName="test"
            trigger="Can I rent out moving boxes from your company?"
          >
            {" "}
            <div className="faqBox">
              <p>
                Absolutely. We rent eco-friendly plastic boxes for your moving
                needs. Please visit our Boxes & Packing Supplies section.
              </p>
            </div>
          </Collapsible>
          <Collapsible
            contentContainerTagName="test"
            trigger="What is the location of your official offices?"
          >
            {" "}
            <div className="faqBox">
              <p>
                We have multiple facilities around Greater Vancouver Area. Our
                main warehouse office is at 15 Braid Street, New Westminster,
                V3L 5N7, British Columbia
              </p>
            </div>
          </Collapsible>
          <Collapsible
            contentContainerTagName="test"
            trigger="What is last minute or urgent moving?"
          >
            {" "}
            <div className="faqBox">
              <p>
                Our last minute/urgent moving services is a moving service
                available without pre-bookings, when you are in need of our
                moving service to come to your place within a few hours. This
                service is useful if your pre-arranged movers from another
                company did not show up, or your pre-arranged movers came with a
                moving truck that’s does not fit all your belongings.
              </p>
            </div>
          </Collapsible>
        </div>
      </Fade>
      <BottomPage />
    </div>
  );
};

export default Faq;
