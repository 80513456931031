import React from "react";
import Fade from "react-reveal/Fade";
import Intro from "../Intro/Intro";
import BottomPage from "../BottomWebsite/BottomWebsite";
import TPics from "../TPics/TPics";

import "./StorageS.css";

import comP from "./img/storage.jpg";
import f1 from "./img/f1.jpg";
import f2 from "./img/f2.jpg";

const StorageS = props => {
  return (
    <div style={{ width: "100%" }}>
      <Intro
        pic={comP}
        introText={
          "We are the Vancouver storage experts – we’ll keep great care of your things."
        }
      />
      <div className="CFBC">
        <div className="CFB">
          <Fade top>
            <h2>Experience Full Service Storage in Vancouver</h2>
          </Fade>
          <Fade bottom>
            <p>
              ABC New Movers Vancouver storage services give you the peace of
              mind in knowing your life’s possessions are being taken care of.
            </p>
          </Fade>
        </div>
      </div>
      <div className="CSBCC">
        <div className="CSBC">
          <div className="CSBCT">
            <Fade left>
              <h3>Our Vancouver Storage Company is home away from home.</h3>
              <p>
                At ABC New Movers we believe moving and storage should go hand
                and hand. That’s why we offer full service storage for all of
                your needs no matter how big and small. You might need to move
                before your new home is ready. Maybe you have more furniture
                than you really need. Or you need to store your grandmother’s
                piano and living room set. No matter what you have to store, ABC
                New Movers moving and storage (Vancouver) will take the same
                care of your stored items that we do of the items that we move
                into your home. Our dedicated Vancouver storage facility is the
                perfect home away from your new home.
              </p>
            </Fade>
          </div>
          <div className="CSBCI">
            <Fade right>
              <img src={f1} alt="" />
            </Fade>
          </div>
        </div>
      </div>
      <div className="CTBCC">
        <div className="CTBC">
          <div className="CTBCT">
            <Fade right>
              <h3>
                Vancouver Storage Professionals – Taking care of your
                possessions like they’re ours.
              </h3>
              <p>
                Our state-of-the-art Vancouver storage facility is designed to
                store everything from a few surplus boxes to even your most
                precious and prized possessions. Our team will catalogue and
                label each box and item so everything is ready to go when you
                are. All of your items are wrapped and protected with care and
                kept on custom shelving away from any potential damage.
              </p>
            </Fade>
          </div>
          <div className="CTBCI">
            <Fade left>
              <img src={f2} alt="" />
            </Fade>
          </div>
        </div>
      </div>
      <TPics />
      <BottomPage />
    </div>
  );
};

export default StorageS;
