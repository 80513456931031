import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "./MainPageForm.css";

const initialState = {
  fName: "",
  lName: "",
  email: "",
  popUp: false,
};

class MainPageForm extends Component {
  constructor(props) {
    super();
    this.state = initialState;
  }

  onFNameChange = (event) => {
    this.setState({ fName: event.target.value });
  };

  onLNameChange = (event) => {
    this.setState({ lName: event.target.value });
  };

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  onSubmitContactForm = (event) => {
    event.preventDefault();
    fetch("https://abcnewmove.herokuapp.com/mainPageEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fName: this.state.fName,
        lName: this.state.lName,
        email: this.state.email,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  sendConfirmEmail = (event) => {
    fetch("https://abcnewmove.herokuapp.com/confirmEmail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.email,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(`Here is a response: ${res}`);
      })
      .catch((err) => {
        console.log(`Here is the error: ${err}`);
      });
  };

  onClick = (event) => {
    this.setState({ popUp: true });
    this.onSubmitContactForm(event);
    this.sendConfirmEmail(event);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.onClick}>
          <label htmlFor="fName">First Name</label>
          <br></br>
          <input
            style={{ backgroundImage: "none" }}
            className="location"
            type="text"
            id="fName"
            name="fName"
            placeholder="First Name"
            onChange={this.onFNameChange}
            required
          />
          <br></br>
          <label htmlFor="lName">Last Name</label>
          <br></br>
          <input
            style={{ backgroundImage: "none" }}
            className="location"
            type="text"
            id="lName"
            name="lName"
            placeholder="Last Name"
            onChange={this.onLNameChange}
            required
          />
          <br></br>
          <label htmlFor="email">Email</label>
          <br></br>
          <input
            style={{ backgroundImage: "none" }}
            className="dateS"
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            onChange={this.onEmailChange}
            required
          />
          <br></br>
          <button>GET QUOTE</button>
        </form>
        <SweetAlert
          show={this.state.popUp}
          success
          title="Mail has been sent!"
          onConfirm={() => this.setState(initialState)}
        />
      </div>
    );
  }
}

export default MainPageForm;
