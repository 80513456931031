import React, { Component } from "react";
import Navigation from "../Navigation/Navigation";
import SideMenu from "../SideMenu/SideMenu";
import Backdrop from "../Backdrop/Backdrop";
import "./Intro.css";

class IntroNav extends Component {
  state = {
    sideMenuOpen: false
  };

  sideMenuToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideMenuOpen: !prevState.sideMenuOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideMenuOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideMenuOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    return (
      <div>
        <Navigation
          sideMenuClickHandler={this.sideMenuToggleClickHandler}
          logo={this.props.logoI}
        />
        <SideMenu
          show={this.state.sideMenuOpen}
          click={this.sideMenuToggleClickHandler}
        />
        {backdrop}
      </div>
    );
  }
}

export default IntroNav;
