import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ScrollToTop from "./components/ScrollToTop/ScrollTotop";
import Radium, { StyleRoot } from "radium";
import { Helmet } from "react-helmet";

import RequestQuote from "./components/RequestQuote/RequestQuote";
import Commercial from "./components/Commercial/Commercial";
import LocalMov from "./components/LocalMov/LocalMov";
import StorageS from "./components/StorageS/StorageS";
import About from "./components/About/About";
import Packing from "./components/Packing/Packing";
import Delivery from "./components/Delivery/Delivery";
import Urgent from "./components/Urgent/Urgent";
import MovingSupplies from "./components/MovingSupplies/MovingSupplies";
import Locations from "./components/Locations/Locations";
import NewWestminster from "./components/LocationControl/CityLocations/NewWestminster";
import Coquitlam from "./components/LocationControl/CityLocations/Coquitlam";
import Vancouver from "./components/LocationControl/CityLocations/Vancouver";
import Burnaby from "./components/LocationControl/CityLocations/Burnaby";
import Faq from "./components/Faq/Faq";
import Comment from "./components/Comment/Comment";
import Contact from "./components/Contact/Contact";
import Consultation from "./components/Consultation/Consultation";
import Careers from "./components/Careers/Careers";
import Gallery from "./components/Gallery/Gallery";

import "./App.css";

import IntroNav from "./components/IntroNav/IntroNav";

import MainPage from "./components/MainPage/MainPage";

import logo from "./components/Navigation/logo.png";

function App() {
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABC New Movers - Moving is Simple!</title>
        <meta
          name="description"
          content="ABC New Move offers reliable residential, commercial moving, delivery and storage services in Metro Vancouver, British Columbia"
        />
        <meta
          name="keywords"
          content="abc new move, abcnewmoving,  moving, mover, company, storage, depot, movers, urgent, transportation, track, last minute moving, depository,
      reliable, service, home, apartment, condo, delivery, pick up, phone, free quote, BC, British Columbia, residential,  commercial salvatory, lower mainland, Vancouver,
      North Vancouver, West Vancouver, Moving Canada BC, moving, move, mover, storage, residential,  commercial , depot, movers, urgent moving,  last minute moving, transportation, 
      track, last minute moving,  depository, reliable, service, North Vancouver, Coquitlam,  moving track, moving companies movers, packers and movers, removal companies, moving services, moving companies near me, movers near me,furniture movers,
      long distance movers, national moving companies, moving containers, moving vans, moving help, removals, long distance moving companies, moving quotes, local movers, the moving company, house movers, house removals,
      removal van, cross country movers, packers & movers, local moving companies, moving van, relocation services, best moving companies, nationwide movers, professional movers, cross country moving companies, furniture removals,
      moving costs, moving options, office movers, removal vans, professional packers and movers, nationwide moving companies, moving storage containers, full service movers, interstate moving companies, the movers, affordable movers,
      relocation companies, home moving companies, moving men, find moving service, interstate movers, professional moving companies, moving estimate, top rated moving companies, state to state moving companies, full service moving companies,
      moving and storage companies, state to state movers, american moving company, apartment movers, moving companies prices, local removal companies, professional packers, moving van lines, commercial movers,
      find a mover, moving storage, best long distance moving companies, van lines, removal services, national removal companies, movers n packers, moving labor, packers and movers services, best movers, home moving services,
      out of state movers, hire movers, best rated moving companies, moving company quotes, moving truck companies, house moving companies, moving van companies, american movers, packing services, household moving companies, national movers,
      budget moving, office relocation, moving cost estimator, of state moving companies, cheapest moving company, house removal companies, best long distance movers, packing companies, furniture removal companies, house moving costs,
      affordable moving companies, furniture moving companies, packers and movers near me, household movers, removal firms, moving companies in, van lines moving company, cheap movers near me, movers near me cheap, furniture movers near me, local moving companies near me, short distance movers near me,
      new westminster movers, new westminster, new, westminster, best movers new westminster, new westminster moving company, moving company, movers in new westminster bc, coquitlam movers, movers coquitlam bc, movers coquitlam area, port coquitlam movers, cheap movers coquitlam, best movers coquitlam,
      metropolitan movers coquitlam, best movers coquitlam bc, burnaby movers, movers burnaby bc, burnaby furniture movers, cheap movers burnaby, burnaby moving company, movers in burnaby, movers in burnaby bc"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Route
        render={({ location }) => (
          <StyleRoot>
            <div style={{ height: "100%" }}>
              <IntroNav logoI={logo} />
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={300}
                  classNames="fade"
                >
                  <Switch location={location}>
                    <ScrollToTop>
                      <Route exact path="/">
                        <MainPage />
                      </Route>
                      <Route path="/request-quote">
                        <RequestQuote />
                      </Route>
                      <Route path="/commercial">
                        <Commercial />
                      </Route>
                      <Route path="/local">
                        <LocalMov />
                      </Route>
                      <Route path="/storage">
                        <StorageS />
                      </Route>
                      <Route path="/about">
                        <About />
                      </Route>
                      <Route path="/packing">
                        <Packing />
                      </Route>
                      <Route path="/delivery">
                        <Delivery />
                      </Route>
                      <Route path="/urgent">
                        <Urgent />
                      </Route>
                      <Route path="/moving-supplies">
                        <MovingSupplies />
                      </Route>
                      <Route exact path="/locations">
                        <Locations />
                      </Route>
                      <Route path="/locations/newwestminster">
                        <NewWestminster />
                      </Route>
                      <Route path="/locations/coquitlam">
                        <Coquitlam />
                      </Route>
                      <Route path="/locations/vancouver">
                        <Vancouver />
                      </Route>
                      <Route path="/locations/burnaby">
                        <Burnaby />
                      </Route>
                      <Route path="/faq">
                        <Faq />
                      </Route>
                      <Route path="/comment">
                        <Comment />
                      </Route>
                      <Route path="/contact">
                        <Contact />
                      </Route>
                      <Route path="/book-consultation">
                        <Consultation />
                      </Route>
                      <Route path="/careers">
                        <Careers />
                      </Route>
                      <Route path="/gallery">
                        <Gallery />
                      </Route>
                    </ScrollToTop>
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </StyleRoot>
        )}
      />
    </Router>
  );
}

export default Radium(App);
