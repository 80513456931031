import React from "react";
import { Link } from "react-router-dom";
import "./SideMenu.css";
import logo from "./white_logo.png";
import closeIcon from "./closeIcon.png";

const SideMenu = (props) => {
  let sideClasses = "sideMenu";
  if (props.show) {
    sideClasses = "sideMenu open";
  }

  return (
    <nav className={sideClasses}>
      <div className="container">
        <button onClick={props.click}>
          <img src={closeIcon} alt="" height="30" />
        </button>
        <div className="logo">
          <img src={logo} alt="" height="60" />
        </div>
      </div>
      <ul className="ulButton">
        <li>
          <Link to="/request-quote" id="secondA" onClick={props.click}>
            GET A FREE QUOTE
          </Link>
        </li>
        <li>
          <Link to="/book-consultation" id="firstA" onClick={props.click}>
            BOOK CONSULTATION
          </Link>
        </li>
        <div className="fUlLi">
          <li>
            <Link to="/" onClick={props.click}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={props.click}>
              About ABC New Movers
            </Link>
          </li>
          <li>
            <Link to="/commercial" onClick={props.click}>
              Commercial Moving
            </Link>
          </li>
          <li>
            <Link to="/local" onClick={props.click}>
              Residential Moving
            </Link>
          </li>
          <li>
            <Link to="/storage" onClick={props.click}>
              Storage Services
            </Link>
          </li>
          <li>
            <Link to="/packing" onClick={props.click}>
              Packing Services
            </Link>
          </li>
          <li>
            <Link to="/delivery" onClick={props.click}>
              Delivery Service
            </Link>
          </li>
          <li>
            <Link to="/urgent" onClick={props.click}>
              Urgent Moving
            </Link>
          </li>
          <li>
            <Link to="/moving-supplies">Moving Supplies</Link>
          </li>
          <br></br>

          <li>
            <Link to="/locations" onClick={props.click}>
              Locations
            </Link>
          </li>
          <li>
            <Link to="/faq" onClick={props.click}>
              Moving FAQ
            </Link>
          </li>

          <li>
            <Link to="/comment" onClick={props.click}>
              Comments/Complaint
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={props.click}>
              Contact
            </Link>
          </li>
        </div>
      </ul>
    </nav>
  );
};

export default SideMenu;
